import apiClient from '@/services/axios'

export const getBudgetPlan = async (customerId = '') => {
  const storedUserData = localStorage.getItem('userData')
  const userData = JSON.parse(storedUserData)
  const res = await apiClient.get('/dcOwner/budget-plan', {
    params: { userId: userData.user.id, customerId },
  })
  if (res) {
    return res.data
  } else {
    return []
  }
}
